:root {
  --safe-area-top: --ion-safe-area-top;
  --safe-area-bottom: --ion-safe-area-bottom;
  --safe-area-left: --ion-safe-area-left;
  --safe-area-right: --ion-safe-area-right;
  --safe-statusbar: --ion-statusbar-padding;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --ion-font-family: "Lato", "Roboto", "Helvetica Neue", sans-serif;
  --ion-default-font: "Lato", "Roboto", "Helvetica Neue", sans-serif;
  --text-black: #141414;
  --dark-gray: #86868D;
  --medium-gray: #999999;
  --disabled-gray: #EBEBEB;
  --navigation: #EBEBEB;
  --row-gray-light: #FAFAFA;
  --row-gray-dark: #F7F7F7;
  --primary: #008383;
  --secondary: #55B7B1;
  --tertiary: #B2E7E3;
  --blue-button: #0385AC;
  --link-blue: #2AA3C6;
  --notes: #EE00F3;
  --background-black: #141414;
  --placeholder: #C9C9C9;
  --warning: #E26B6B;
  --alert: #FFCA19;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

#__next {
  height: 100%;
  width: 100vw;
  position: fixed;
}

@media (max-width: 767px) {
  ion-menu::part(container) {
    width: 100%;
  }
}

.toolbar-background {
  background: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.notification-container {
  font-family: 'Lato', 'sans-serif';
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  max-width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  font-family: 'Lato';
  box-sizing: border-box;
  padding: 15px 15px 15px 15px;
  border-radius: 3px;
  border: 3px solid #ccc;
  color: black;
  background-color: white;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  color: var(--primary);
  font-size: 1em;
  line-height: 24px;
  font-size: 20px;
  padding-bottom: 5px;
  font-weight: 300;
  margin: 0 0 5px 0;
}

.notification .message {
  color: black !important;
  letter-spacing: -1%;
  line-height: 20px;
  font-weight: 300;
  font-size: 14px;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Lato';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info .title {
  color: var(--primary);
}

.notification-info:before {
  content: "";
}

.notification-success .title {
  color: #51a351;
}

.notification-success:before {
  content: "";
}

.notification-warning .title {
  color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error .title {
  color: #bd362f;
}

.notification-error:before {
  content: "";
}

html, body {
  touch-action: none;
  position: absolute;
}

ion-header {
  display: block;
  position: relative;
  -ms-flex-order: -1;
  order: -1;
  width: 100%;
  z-index: 10;
}

ion-footer {
  display: block;
  position: relative;
  -ms-flex-order: 1;
  order: 1;
  width: 100%;
  z-index: 10;
}

ion-footer ion-toolbar:last-of-type {
  padding-bottom: var(--ion-safe-area-bottom, 0);
}

ion-header ion-toolbar:first-of-type {
  padding-top: var(--ion-safe-area-top, 0);
}